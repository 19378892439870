<template>
    <div :class="`${containerClass} my-4`">
        <div :class="`${cardClass} card`">
            <div class="card-header">
                <span v-if="loading">Imported items</span>
                <div v-else class="d-flex align-items-center">
                    <div class="d-flex flex-column">
                        Imported items for {{ product.name }}
                        <small>In stock: {{ product.quantity }}</small>
                    </div>

                    <div class="form-group ml-auto mb-0" style="width: 195px">
                        <input
                            type="text"
                            class="form-control form-control-sm form-control form-control-sm my-auto"
                            v-model="searchText"
                            placeholder="Search"
                        />
                    </div>
                </div>
            </div>
            <div class="card-body">
                <Spinner v-if="loading" size="60" line-fg-color="gray" />
                <div v-if="!loading && product.items">
                    <span v-if="!product.items.length">No items are in stock for this product</span>
                    <table v-else class="table table-sm table-borderless table-responsive" id="product-items-table">
                        <thead>
                            <tr>
                                <th>UUID</th>
                                <th>Import date</th>
                                <th>Price</th>
                                <th v-for="field in product.fields">{{ field.type_name }}</th>
                                <th>Stock status</th>
                                <th v-if="showOrderColumn"></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in product.items" :key="item.id">
                                <td>
                                    <span @click="copyUUID(item.id, item.uuid)" style="text-decoration: underline;">
                                        {{ item.uuid }}
                                    </span>
                                </td>
                                <td>{{ formatDate(item.created_at) }}</td>
                                <td>{{ moneyFormat(item.cost) }}</td>
                                <td
                                    v-for="field in product.fields"
                                >{{ getFieldData(field.id, item) }}</td>
                                <td>
                                    <span style="cursor: pointer;"@click="toggleInStock(item, index)">
                                        <ToggleButton
                                            style="pointer-events: none;"
                                            :value="item.in_stock"
                                            :labels="{checked: 'In', unchecked: 'Out'}"
                                            :width="60"
                                            :sync="true"
                                            :font-size="15"
                                        />
                                    </span>
                                </td>
                                <!-- <td
                                    style="cursor: pointer; min-width: 147px;"
                                    @click="toggleInStock(item, index)"
                                >{{ item.in_stock ? 'In stock' : 'Out of stock' }}</td>-->
                                <td v-if="showOrderColumn">
                                    <ViewOrderButton v-if="item.order" :orderId="item.order" />
                                </td>
                                <td>
                                    <button type="button"
                                            data-toggle="dropdown"
                                            class="btn btn-link text-secondary p-1">
                                        <i class="fas fa-1x fa-ellipsis-h"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <router-link class="dropdown-item"
                                                     :to="{name: 'product-item-details', params: {product_id: product.id, item_id: item.id}}">Options</router-link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { getProductItems } from '../../services/ProductService';
import { updateItem } from '../../services/ItemService';
import dataTableMixin from '../../mixins/dataTable';
import Spinner from 'vue-simple-spinner';
import { ToggleButton } from 'vue-js-toggle-button';
import ViewOrderButton from '../../components/shared/ViewOrderButton';
import moneyFormat from "../../mixins/moneyFormat";

export default {
    name: 'ProductItems',
    components: {
        Spinner,
        ToggleButton,
        ViewOrderButton
    },
    mixins: [
        dataTableMixin,
        moneyFormat
    ],
    data() {
        return {
            product: {
                items: []
            },
            copiedId: '',
            loading: false,
            loadingOrder: false
        };
    },
    computed: {
        hasFields() {
            if (this.product.items && this.product.items.length) {
                return this.product.fields && this.product.fields.length > 0;
            }
            return false;
        },
        showOrderColumn() {
            return this.product.items.filter(item => item.order).length > 0;
        },
        containerClass() {
            return this.hasFields ? 'container-fluid' : 'container';
        },
        cardClass() {
            return this.hasFields && this.product.fields.length ? 'w-75 mx-auto' : '';
        }
    },
    mounted() {
        this.getProduct();
    },
    methods: {
        async getProduct() {
            try {
                this.loading = true;
                const response = await getProductItems(this.$route.params.id);
                this.product = response.data;
                this.createDataTable('#product-items-table');
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },
        getFieldData(fieldId, item) {
            const fieldData = item.field_data.find(data => data.field === fieldId);
            if (fieldData) {
                return fieldData.field_is_date ? this.formatDate(fieldData.data) : fieldData.data;
            } else {
                return 'N/A';
            }
        },
        async toggleInStock(item, index) {
            try {
                await updateItem(item.id, { in_stock: !item.in_stock });
                this.product.items[index].in_stock = !item.in_stock;
                this.product.quantity += item.in_stock ? 1 : -1;
            } catch (error) {
                console.log(error);
            }
        },
        copyUUID(id, uuid) {
            const el = document.createElement('textarea');
            el.value = uuid;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            this.copiedId = id;
        },
        formatDate(date) {
            return moment(date).format('ll');
        }
    }
};
</script>

<style scoped>
.copy-btn {
    min-width: 82px;
}

.see-order-btn {
    min-width: 115px;
}

label.vue-js-switch {
    margin-bottom: 0;
}
</style>